import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'
import { Error, Success, SuccessBorder, Warning, WarningBorder } from '../../../../pure/libs/ColorsExperimental'
import { ImageKey, TextKey } from '../../../../pure/types/Antiloop'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { BIG_ICON_SIZE } from './Icons'
import Loader from './Loader'

type Type = 'success' | 'warning' | 'error' | 'processing'

export const VitecNextOrderItemStatusView = ({
  textKey,
  imageKey,
  text,
  type,
  SuffixCompoent,
  onClick
}: {
  textKey: TextKey
  imageKey?: ImageKey
  SuffixCompoent?: React.FC
  text?: string
  type: Type
  onClick?: () => unknown
}) => {
  const Container = type === 'success' ? SuccessContainer : type === 'error' ? ErrorContainer : WarningContainer

  if (!imageKey && type === 'success') imageKey = Images.roundCheckIconWhite
  if (!imageKey && type === 'error') imageKey = Images.warningIcon
  if (!imageKey && type === 'warning') imageKey = Images.warningIcon

  return (
    <Container fullWidth fullPadding spacing={smallSpacing} align="center" onClick={onClick} link={!!onClick}>
      <Box direction="row" align="center">
        {imageKey && (
          <Box>
            <FigmaImageContainer imageKey={imageKey} width={BIG_ICON_SIZE} />
          </Box>
        )}
        {type === 'processing' && (
          <Box>
            <Loader $loaderColor={Colors.white} size={BIG_ICON_SIZE} />
          </Box>
        )}
        <Box left spacing={smallSpacing}>
          <FigmaText textKey={textKey} text={text} />
        </Box>
        {SuffixCompoent && <SuffixCompoent />}
      </Box>
    </Container>
  )
}

const BadgeContainer = styled(Box)`
  border-radius: ${BorderRadixes.regular};
`

const SuccessContainer = styled(BadgeContainer)`
  background-color: ${Success};
  border: 1px solid ${SuccessBorder};
`

const WarningContainer = styled(BadgeContainer)`
  background-color: ${Warning};
  border: 1px solid ${WarningBorder};
`

const ErrorContainer = styled(BadgeContainer)`
  background-color: ${Error};
  border-radius: ${BorderRadixes.regular};
`

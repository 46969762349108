import styled from '@emotion/styled'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { Texts as AppTexts } from '../../../../pure/libs/AppTexts'
import Colors from '../../../../pure/libs/Colors'
import { UploadedFile } from '../../../../pure/types/JaguarTypes'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { updateUploadedFile } from '../libs/DBApiHandler'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { CheckBoxOutlineBlank, VitecNextOrderArrowBackIcon } from './Icons'
import { NoWrapFigmaText } from './StyledComponents'
import { VitecNextOrderProductButtons } from './VitecNextOrderProductButtons'
import { VitecNextOrderProductImageCaptionsResult } from './VitecNextOrderProductImageCaptionsResult'
import { LoadingPage } from './LoadingPage'

export const VitecNextOrderProductImageCaptions = ({ vitecNextOrderId }: VitecNextOrderProductProps) => {
  const navigate = useNavigate()
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.IMAGE_CAPTIONS
  const uploadId = leopardOrder?.requestId

  const { data: upload } = useUpload(uploadId)
  const { data: uploadedFiles = [] } = useUploadedFiles(upload?.id)
  const uploadedFilesChecked = uploadedFiles.filter((file) => file?.metaData?.checked !== false)

  const [checked, setChecked] = React.useState(true)

  const onClickSelectAll = () => {
    if (!upload) return
    const newValue = !checked
    setChecked(newValue)
    uploadedFiles.forEach((file) => {
      updateUploadedFile(
        {
          ...file,
          metaData: { ...file?.metaData, checked: newValue }
        } as UploadedFile,
        upload
      )
    })
  }

  const onClickBack = () => navigate(-1)

  if (isLoading) return <LoadingPage />
  if (!vitecNextOrder) return <Box />

  return (
    <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
      <Box direction="row" pointer onClick={onClickBack}>
        <Box>
          <VitecNextOrderArrowBackIcon />
        </Box>
        <Box left spacing={smallSpacing}>
          <NoWrapFigmaText textKey={Texts.standardReturnToDashboard} />
        </Box>
      </Box>
      <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
        <Box fullWidth>
          <FigmaText textKey={Texts.ImageTextsItPreviewHeader} />
        </Box>
        <Box top>
          <FigmaImageContainer imageKey={Images.gazelleInCircle} />
        </Box>
        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderProductButtons
            fulfillMentSuccessText={formatText(getFigmaText(Texts.ImageTextsNotificationItCompleted), [
              uploadedFilesChecked.length
            ])}
            vitecNextOrder={vitecNextOrder}
            vitecNextProduct={VitecNextProduct.IMAGE_CAPTIONS}
            suffixElement={
              <Box right pointer onClick={() => onClickSelectAll()}>
                <Box direction="row" align="center">
                  <NoWrapFigmaText
                    styleTextKey={Texts.ImageTextsItCssImageText}
                    textKey={AppTexts.cationsSelectAllButton}
                  />
                  <Box left spacing={smallSpacing}>
                    {checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlank />}
                  </Box>
                </Box>
              </Box>
            }
          />
        </Box>
        <Box top>
          <FigmaText
            textKey={Texts.ImageTextsItPreviewSummary}
            text={formatText(getFigmaText(Texts.ImageTextsItPreviewSummary), [uploadedFiles.length])}
          />
        </Box>
        <Box top spacing={tinySpacing} />
        {leopardOrder?.status === 'processing' && <LoadingPage />}
        {leopardOrder?.status !== 'processing' && (
          <Container align="center" fullWidth>
            <Box direction="row" style={{ flexWrap: 'wrap' }} fullPadding fullWidth justify="center">
              {uploadedFiles?.map((file, key) => {
                return (
                  <Box key={key} width="50%" fullPadding spacing={smallSpacing}>
                    <VitecNextOrderProductImageCaptionsResult file={file} upload={upload} index={key} />
                  </Box>
                )
              })}
            </Box>
          </Container>
        )}
      </Box>
      <Box />
    </Box>
  )
}

function getImageDescription(b: UploadedFile): string {
  const { metaData, PropertyAdditionalInfo } = b
  const imageDescription = metaData?.userEditedText || PropertyAdditionalInfo || ''
  return imageDescription
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

const StyledCheckboxIcon = styled(CheckBoxIcon)`
  color: ${Colors.white};
`

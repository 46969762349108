import React from 'react'
import Loader from './Loader'
import { rgba2hex } from '../libs/ColorHelper'

export type IconProps = Props

type Props = {
  className?: string
  component: any
  size?: number
  onClick?: () => void
  color: string
  backgroundColor?: string
  borderRadius?: string
  isLoading?: boolean
}

export const DEFAULT_ICON_SIZE = 16

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const { component, backgroundColor, color, borderRadius, isLoading, size, ...iconProps } = props

  if (isLoading) return <Loader size={size || DEFAULT_ICON_SIZE} />
  return React.createElement(component, {
    ...iconProps,
    style: getVariantStyle(props)
  })
}

const getVariantStyle = ({ size, color, backgroundColor = 'transparent', borderRadius }: IconProps) => ({
  width: size || DEFAULT_ICON_SIZE,
  height: size || DEFAULT_ICON_SIZE,
  borderRadius: borderRadius || (size || DEFAULT_ICON_SIZE) / 2,
  backgroundColor,
  color: !!color && color.startsWith('rgb') ? rgba2hex(color) : color,
  lineHeight: `${size || DEFAULT_ICON_SIZE}px`
})

export default Icon

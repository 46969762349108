import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { Upload, UploadedFile } from '../../../../pure/types/JaguarTypes'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { isProcessingJaguarResponse, isTimedoutJaguarResponse } from '../libs/VitecNextOrderItemHelper'
import { VitecNextProductInfoItemLoadingProps } from '../types/VitecNextProductTypes'
import Box from './Box'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

export const VitecNextOrderItemStatus = (
  props: Partial<VitecNextProductInfoItemLoadingProps> & {
    upload?: Upload
    uploadedFiles?: UploadedFile[]
    vitecNextProduct: VitecNextProduct
    enableDoneStatus?: boolean
    vitecNextOrder?: VitecNextOrder
    successText?: string
  }
) => {
  const { uploadedFiles = [], successText } = props
  const navigate = useNavigate()
  const hasFailedAllUploadedFiles = uploadedFiles.length > 0 && uploadedFiles.every((file) => !!file.error)

  const { upload, enableDoneStatus = true, vitecNextOrder, vitecNextProduct } = props
  const jaguarResponse = props.vitecNextOrder?.jaguarResponses?.[props.vitecNextProduct]
  const { isProductEnabled } = props

  if (!vitecNextOrder) return <Box />

  if (!jaguarResponse) return <Box />

  const { requestId } = jaguarResponse
  const status = jaguarResponse?.status

  if (!isProductEnabled?.isEnabled) return <Box />

  if (!requestId) return <Box />

  if (upload?.status === 'error')
    return <VitecNextOrderItemStatusView textKey={Texts.standardNotificationCiError} type="error" />

  if (hasFailedAllUploadedFiles)
    return <VitecNextOrderItemStatusView textKey={Texts.standardNotificationCiError} type="error" />

  if (status === 'failed')
    return <VitecNextOrderItemStatusView textKey={Texts.standardNotificationCiError} type="error" />

  if (isTimedoutJaguarResponse(jaguarResponse))
    return <VitecNextOrderItemStatusView type="processing" textKey={Texts.ImageTextsItJobTakingToLong} />

  if (isProcessingJaguarResponse(jaguarResponse))
    return <VitecNextOrderItemStatusView type="processing" textKey={Texts.standardNotificationInProgress} />

  if (enableDoneStatus && status === 'done')
    return (
      <Box
        link
        onClick={() =>
          navigate(formatRoutePathVitecNextOrderProduct({ vitecNextOrderId: vitecNextOrder._id, vitecNextProduct }))
        }
        fullWidth
      >
        <VitecNextOrderItemStatusView
          textKey={Texts.standardNotificationCiCompleted}
          type="success"
          text={`${successText || getFigmaText(Texts.standardNotificationCiCompleted)} (${dayjs(
            jaguarResponse.finishedAt || jaguarResponse.createdAt
          ).format('DD/M HH:mm')})`}
        />
      </Box>
    )

  return <Box />
}

import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Images from '../libs/Images'
import { createLeopardOrder, shortAndLongDescription } from '../libs/ProcessHandler'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import { VitecNextOrderItemStatusByggemate } from './VitecNextOrderItemStatusByggemate'
import { VitecNextOrderItemStatusImageCaptions } from './VitecNextOrderItemStatusImageCaptions'
import { VitecNextOrderItemStatusLongAndShortDescription } from './VitecNextOrderItemStatusLongAndShortDescription'
import { VitecNextOrderProductContructionInfo } from './VitecNextOrderProductContructionInfo'
import { VitecNextOrderProductImageCaptions } from './VitecNextOrderProductImageCaptions'
import { VitecNextOrderProductLongShortDescription } from './VitecNextOrderProductLongShortDescription'

export const VitecNextProductInfoItems: {
  [property in VitecNextProduct]: VitecNextProductInfoItem
} = {
  [VitecNextProduct.IMAGE_CAPTIONS]: {
    textKey: Texts.dashboardAutoCaptioningTitleCaptioningTitleg,
    descriptionKey: Texts.dashboardAutoCaptioningDescr,
    imageKey: Images.imageCaptionIcon,
    slug: 'bildetekst',
    PreviewComponent: VitecNextOrderProductImageCaptions,
    StatusComponent: VitecNextOrderItemStatusImageCaptions,
    vitecNextProduct: VitecNextProduct.IMAGE_CAPTIONS,
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsCtaGenImageTexts,
        getButtonText: (r?: IsVitecNextProductEnabledResponse) =>
          formatText(getFigmaText(Texts.ImageTextsCtaGenImageTexts), [r?.noOfDocuments || '0']),
        onClick: createLeopardOrder
      }
    ]
  },
  [VitecNextProduct.BYGGEMÅTE]: {
    textKey: Texts.dashboardConstructionInfoTitle,
    descriptionKey: Texts.dashboardConstructionInfoDescr,
    imageKey: Images.constructionInfoIcon,
    slug: 'byggemate',
    PreviewComponent: VitecNextOrderProductContructionInfo,
    StatusComponent: VitecNextOrderItemStatusByggemate,
    vitecNextProduct: VitecNextProduct.BYGGEMÅTE,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: createLeopardOrder
      }
    ]
  },
  [VitecNextProduct.SHORT_LONG_DESCRIPTION]: {
    textKey: Texts.dashboardShortLongTitle,
    descriptionKey: Texts.dashboardShortLongDescr,
    imageKey: Images.headerAndShortDescriptionIcon,
    slug: 'short-and-long',
    PreviewComponent: VitecNextOrderProductLongShortDescription,
    StatusComponent: VitecNextOrderItemStatusLongAndShortDescription,
    vitecNextProduct: VitecNextProduct.SHORT_LONG_DESCRIPTION,
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsSelectPinpointText,
        onClick: (s, context) => {
          return shortAndLongDescription({
            vitecNOOrder: s.vitecNextOrder,
            longShortDescriptionType: 'bullets',
            state: context.state
          })
        }
      },
      {
        buttonTextKey: Texts.ImageTextsSelectFloatingText,
        onClick: (s, context) => {
          return shortAndLongDescription({
            vitecNOOrder: s.vitecNextOrder,
            longShortDescriptionType: 'long',
            state: context.state
          })
        }
      }
    ]
  },
  [VitecNextProduct.VALIDATE_AD]: {
    textKey: Texts.dashboardAdValidationTitle,
    descriptionKey: Texts.dashboardAdValidationDescr,
    imageKey: Images.adValidationIcon,
    slug: 'ad-validation',
    vitecNextProduct: VitecNextProduct.VALIDATE_AD,
    buttons: [{ buttonTextKey: Texts.dashboardCtaGenerate, onClick: createLeopardOrder }]
  }
}

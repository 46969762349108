import JsonTexts from '../assets/texts.json'

export const Texts = JsonTexts

export const HardCodedTexts = {
  orderViewHeader: {
    name: 'orderViewHeader',
    characters: 'Hjelms gate 2B, Oslo',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '32px',
      fontWeight: 500,
      letterSpacing: '-0.64px',
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: '🖥️ Gazelle App Production',
    figmaTextKeyName: 'TXT_OrderViewHeader'
  }
}

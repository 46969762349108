import { doc, getDoc, query } from 'firebase/firestore'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import Collections from '../../../../pure/libs/Collections'
import config from '../Config'
import { db } from '../libs/Firebase'
import { useFeatureFlags } from './QueryHooks'
import useQueryParams from '../libs/useQueryParams'
import { getFeatureFlagsFromUrl } from '../libs/Common'

const featureFlagEnvironment = config.name

const featureFlagsFromUrl = getFeatureFlagsFromUrl()

export const useFeatureFlag = (flag: FeatureFlag): boolean | undefined => {
  const { data: flags } = useFeatureFlags(featureFlagEnvironment)

  if (featureFlagEnvironment === 'local') return config[flag]

  if (featureFlagsFromUrl[flag]) return true

  return flags?.[flag]
}

export const getFeatureFlag = (flag: FeatureFlag) =>
  getDoc(doc(db, Collections.FEATURE_FLAGS, featureFlagEnvironment)).then((doc) => doc.data()?.[flag])

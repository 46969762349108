import dayjs from 'dayjs'
import invariant from 'invariant'
import { ShortLongDescriptionRequestWithType } from '../../../../pure/leopard/JaguarTypesDerived'
import { VitecNextProduct, VitecNextProductPreviewFeatureFlag } from '../../../../pure/leopard/VitecNextProduct'
import { createUniqueId } from '../../../../pure/libs/Common'
import { OrganizationTemplates } from '../../../../pure/libs/OrganizationTemplateHelper'
import { getDefaultPromptForEstate, getKeywordMapItemsForEstate } from '../../../../pure/libs/VitecNOCreateAdHelper'
import { LongShortDescriptionType } from '../../../../pure/types/LongShortDescriptionType'
import { LeopardOrder, VitecNextOrder, VitecNextOrderResultLongShort } from '../../../../pure/types/VitecNODerivedTypes'
import config from '../Config'
import { AppContext, State } from '../hooks/useAppState'
import { map } from '../hooks/useExternalSessionForVitecNOOrder'
import { getFeatureFlag } from '../hooks/useFeatureFlag'
import { createJaguarOrderForVitecNextOrder } from '../libs/CloudFunctionsApiHandler'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { getEstateForVitecNextOrder, getFileSetResults, shortLongDescription } from './CloudFunctionsApiHandler'
import { updateVitecNOOrder } from './DBApiHandler'
import { getPlacePredictionsForExternalSession } from './GoogleMaps'

export const createLeopardOrder = async (
  {
    vitecNextOrder,
    vitecNextProduct,
    requestId = createUniqueId()
  }: { requestId?: string; vitecNextOrder: VitecNextOrder; vitecNextProduct: VitecNextProduct },
  { state }: AppContext
) => {
  const enablePreview = await getFeatureFlag(VitecNextProductPreviewFeatureFlag[vitecNextProduct])

  const leopardOrder: LeopardOrder = {
    _id: requestId,
    requestId,
    status: 'processing',
    createdAt: dayjs().format(),
    enablePreview,
    finishedAt: null,
    product: vitecNextProduct
  }

  vitecNextOrder = await updateVitecNOOrder({
    ...vitecNextOrder,
    jaguarResponses: {
      ...vitecNextOrder.jaguarResponses,
      [vitecNextProduct]: leopardOrder
    }
  })

  await upsertLeopardOrder({ leopardOrder, vitecNextOrder })

  await createJaguarOrderForVitecNextOrder(
    {
      requestId,
      vitecNextOrderId: vitecNextOrder.orderId,
      vitecNextProduct
    },
    state
  )

  return vitecNextOrder
}

export const shortAndLongDescription = async ({
  vitecNOOrder,
  longShortDescriptionType,
  state
}: {
  vitecNOOrder: VitecNextOrder
  longShortDescriptionType: LongShortDescriptionType
  state: State
}) => {
  const requestId = createUniqueId()
  const createdAt = dayjs().format()

  await updateVitecNOOrder({
    ...vitecNOOrder,
    jaguarResponses: {
      ...vitecNOOrder.jaguarResponses,
      SHORT_LONG_DESCRIPTION: {
        _id: requestId,
        createdAt,
        requestId,
        status: 'processing',
        product: VitecNextProduct.SHORT_LONG_DESCRIPTION
      }
    }
  })

  const estate = await getEstateForVitecNextOrder(vitecNOOrder, state)
  invariant(estate, 'Estate is required for vitecNOOrderId: %s', vitecNOOrder.orderId)

  const externalSession = map(estate)

  // eslint-disable-next-line prefer-const
  let googlePlace = await getPlacePredictionsForExternalSession(externalSession)

  const keywordMapItems = getKeywordMapItemsForEstate(estate)

  const { organizationId } = OrganizationTemplates.eiendomsmegler1

  const req = {
    requestId,
    organizationId,
    type: 'overview',
    languageCode: 'no',
    style: 'Detailed',
    targetAudience: 'general',
    adLength: 'short',
    logAdInJaguar: config.enableLogAdInJaguar,
    placesId: googlePlace?.place_id,
    keywordMapItems,
    propertyInfo: estate ? getDefaultPromptForEstate(estate) : '',
    longShortDescriptionType
  } as ShortLongDescriptionRequestWithType

  const shortLongDescriptionResponse = await shortLongDescription(req, state).then((res) =>
    getFileSetResults({ requestId }, state)
  )

  const result = shortLongDescriptionResponse?.fileResults?.[0]?.result || {}
  //@ts-ignore
  const { longDescription, shortDescription, longDescriptionPinpoints } = result

  invariant(longDescription || longDescriptionPinpoints, 'longDescription || longDescriptionPinpoints is required')
  invariant(shortDescription, 'shortDescription is required')
  invariant(longShortDescriptionType, 'longShortDescriptionType is required')

  const extra: VitecNextOrderResultLongShort = {
    longDescription,
    shortDescription,
    longShortDescriptionType
  }

  return updateVitecNOOrder({
    ...vitecNOOrder,
    jaguarResponses: {
      ...vitecNOOrder.jaguarResponses,
      SHORT_LONG_DESCRIPTION: {
        _id: requestId,
        createdAt,
        finishedAt: dayjs().format(),
        requestId,
        status: 'done',
        extra,
        product: VitecNextProduct.SHORT_LONG_DESCRIPTION
      }
    }
  })
}

import invariant from 'invariant'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Images from '../../../../pure/libs/AppImages'
import { Texts as AppTexts } from '../../../../pure/libs/AppTexts'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { VitecNextOrderArrowBackIcon } from './Icons'
import { LoadingPage } from './LoadingPage'
import { NoWrapFigmaText } from './StyledComponents'
import { VitecNextOrder } from './VitecNextOrder'
import { VitecNextOrderProductLongShortDescriptionItem } from './VitecNextOrderProductLongShortDescriptionItem'

export const VitecNextOrderProductLongShortDescription: React.FC<VitecNextOrderProductProps> = ({
  vitecNextOrderId
}) => {
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const navigate = useNavigate()

  if (isLoading) return <LoadingPage />

  const e = vitecNextOrder?.jaguarResponses?.SHORT_LONG_DESCRIPTION?.extra

  if (!e) return <LoadingPage />

  invariant(e, 'Missing data for SHORT_LONG_DESCRIPTIONon vitecNOOrderId: %s', vitecNextOrder.orderId)

  invariant(e.longDescription, 'Missing longDescription for vitecNOOrderId: %s', vitecNextOrder.orderId)
  invariant(e.shortDescription, 'Missing shortDescription for vitecNOOrderId: %s', vitecNextOrder.orderId)

  return (
    <Box fullWidth>
      <Box fullWidth fullPadding direction="row" justify="space-between">
        <Box direction="row" pointer onClick={() => navigate(-1)}>
          <Box>
            <VitecNextOrderArrowBackIcon />
          </Box>
          <Box left spacing={smallSpacing}>
            <NoWrapFigmaText textKey={Texts.standardReturnToDashboard} />
          </Box>
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <FigmaText textKey={AppTexts.longShortHeader} />
          <Box top fullWidth>
            <Box fullWidth direction="row" align="center" top>
              <FigmaImageContainer imageKey={Images.adCreatorAdGazelle} />
              <Box left spacing={smallSpacing}>
                <FigmaTextWithStyle type="body4" textKey={AppTexts.adCreatorAdFreshlyNewAd} color="white" />
              </Box>
            </Box>
          </Box>
          <Box fullWidth top>
            <VitecNextOrderProductLongShortDescriptionItem
              header={getFigmaText(AppTexts.longShortShortHeadline)}
              description={e.shortDescription}
            />
          </Box>

          <Box fullWidth top spacing={bigSpacing}>
            <VitecNextOrderProductLongShortDescriptionItem header="Kort om eiendomen" description={e.longDescription} />
          </Box>
          <Box fullWidth top spacing={bigSpacing}></Box>
        </Box>
        <Box />
      </Box>
    </Box>
  )
}

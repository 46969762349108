import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath.js'
import { smallSpacing } from '../enums/Spacings.js'
import useAppState from '../hooks/useAppState.js'
import { OnClickVitecNextProductInfoItem, VitecNextProductInfoItemButton } from '../types/VitecNextProductTypes.js'
import Box from './Box.js'

import invariant from 'invariant'
import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse.js'
import { VitecNextProduct, VitecNextProductPreviewFeatureFlag } from '../../../../pure/leopard/VitecNextProduct.js'
import { createUniqueId } from '../../../../pure/libs/Common.js'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { useVitecNOOrder } from '../hooks/QueryHooks.js'
import { useFeatureFlag } from '../hooks/useFeatureFlag.js'
import { getUpload } from '../libs/DBApiHandler.js'
import { captureException } from '../libs/SentryHelper.js'
import { getFigmaText } from '../libs/TextRepository.js'
import { isProcessingJaguarResponse, isTimedoutJaguarResponse } from '../libs/VitecNextOrderItemHelper.js'
import Button from './Button.js'
import { Texts } from '../libs/Texts.js'

type Props = {
  vitecNOOrder: VitecNextOrder
  vitecNextProduct: VitecNextProduct
  button: VitecNextProductInfoItemButton
  isProductEnabled?: IsVitecNextProductEnabledResponse
  index?: number
}

export const VitecNextOrderItemButton = (props: Props): JSX.Element => {
  const navigate = useNavigate()
  const { button, isProductEnabled, index = 0, vitecNextProduct } = props
  const { data: vitecNextOrder = props.vitecNOOrder } = useVitecNOOrder(props.vitecNOOrder._id)
  const vitecNextOrderId = vitecNextOrder._id
  const previewFeatureFlag = VitecNextProductPreviewFeatureFlag[vitecNextProduct]
  const enablePreviewFeatureFlag = useFeatureFlag(previewFeatureFlag)
  const [isLoading, setIsLoading] = useState<boolean>()
  const jaguarResponseStatus = vitecNextOrder.jaguarResponses?.[vitecNextProduct]
  const enablePreviewOnOrder = jaguarResponseStatus?.enablePreview

  const appContext = useAppState()

  const onClick = async (onClick: OnClickVitecNextProductInfoItem) => {
    const requestId = createUniqueId()
    const uploadId = requestId
    setIsLoading(true)

    Promise.resolve()
      .then(async () => {
        const routePath = formatRoutePathVitecNextOrderProduct({ vitecNextOrderId, vitecNextProduct })

        return Promise.resolve()
          .then(() => onClick({ vitecNextOrder, vitecNextProduct, requestId }, appContext))
          .then(async () => {
            if (vitecNextProduct === VitecNextProduct.SHORT_LONG_DESCRIPTION) return navigate(routePath)
            const upload = await getUpload(uploadId)
            invariant(upload, 'upload is required')

            if (upload.status === 'done' && enablePreviewFeatureFlag && enablePreviewOnOrder) navigate(routePath)
          })
          .catch(async (e) => {
            const upload = await getUpload(uploadId)
            // TODO WRITE TEST: Should navigate to preview page in case of error and upload is done
            if (upload?.status === 'done') navigate(routePath)
            throw e
          })
      })
      .catch(captureException)
      .finally(() => setIsLoading(false))
  }

  let buttonText = button.getButtonText?.(isProductEnabled) || getFigmaText(button.buttonTextKey)
  buttonText = buttonText?.replace('Punktmarkeri', 'Punktmakert')

  if (isTimedoutJaguarResponse(jaguarResponseStatus)) buttonText = getFigmaText(Texts.ImageTextsCtaItTryAgain)

  return (
    <Box left={index > 0} spacing={smallSpacing}>
      <Button
        textKey={button.buttonTextKey}
        onClick={() => onClick(button.onClick)}
        text={buttonText}
        disabled={
          // TODO WRITE TEST: Write test for this
          !isTimedoutJaguarResponse(jaguarResponseStatus) &&
          (isProcessingJaguarResponse(jaguarResponseStatus) || isLoading || !isProductEnabled?.isEnabled)
        }
        loading={button.enableLoading && isLoading}
      />
    </Box>
  )
}

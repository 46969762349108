import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import Box from './Box'
import Button from './Button'
import { AdCreatorFileCopy } from './Icons'
import { ButtonProps } from './ButtonPrimary'
import { TextKey } from '../../../../pure/types/Antiloop'
import { Texts } from '../../../../pure/libs/AppTexts'

export function ButtonCopy(props: ButtonProps & { buttonStyleTextKey?: TextKey }) {
  return (
    <Button variant="outlined" spacing={smallSpacing} color={'primary40' as any} {...props}>
      <Box direction="row" align="center">
        <AdCreatorFileCopy />
        <Box left spacing={tinySpacing}>
          <FigmaText
            styleTextKey={props.buttonStyleTextKey}
            textKey={Texts.typeOfAdRowExteriorDescription}
            text="Copy"
          />
        </Box>
      </Box>
    </Button>
  )
}

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import RoutePath from '../../../../pure/libs/RoutePath.js'
import { VitecNOOrderQueryParams } from '../../../../pure/types/VitecNODerivedTypes.js'
import { VitecNextOrder } from '../components/VitecNextOrder.js'
import { addQueryParamsToUrl } from '../hooks/useQueryparamHelper.js'
import useQueryParams from '../libs/useQueryParams.js'

// EstateType: https://gazelle-work.slack.com/archives/C06DUK010G2/p1706649574837279
// Test data: http://localhost:5173/vitec-megler-doc?orderId=66FDAA52-9E8F-4564-9434-19454DF6990D

export const VitecNextOrderPage = () => {
  const { orderId } = useQueryParams<VitecNOOrderQueryParams>()

  return <VitecNextOrder vitecNextOrderId={orderId} />
}

export const VitecNextOrderRedirectPage = ({ vitecNextOrderId }: { vitecNextOrderId: string }) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(addQueryParamsToUrl(RoutePath.VITEC_NEXT_ORDER, { orderId: vitecNextOrderId }))
  }, [])

  return <VitecNextOrder vitecNextOrderId={vitecNextOrderId} />
}

import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'

export function AdCreatorAdSectionResponse({ text }: { text: string; isSelected?: boolean }) {
  return (
    <Box fullWidth>
      <FigmaTextWithLinebreaks
        textKey={Texts.typeOfAdRowExteriorDescription}
        text={text}
        // color={isSelected ? 'white' : 'primary100'}
      />
    </Box>
  )
}

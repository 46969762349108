import { FeatureFlag } from '../enums/FeatureFlags'

export enum VitecNextProduct {
  IMAGE_CAPTIONS = 'IMAGE_CAPTIONS',
  BYGGEMÅTE = 'BYGGEMÅTE',
  SHORT_LONG_DESCRIPTION = 'SHORT_LONG_DESCRIPTION',
  VALIDATE_AD = 'VALIDATE_AD'
}

export const VitecNextProductPreviewFeatureFlag: Record<VitecNextProduct, FeatureFlag> = {
  [VitecNextProduct.IMAGE_CAPTIONS]: FeatureFlag.enablePreviewImageTexts,
  [VitecNextProduct.BYGGEMÅTE]: FeatureFlag.enablePreviewConstructionInfo,
  [VitecNextProduct.SHORT_LONG_DESCRIPTION]: FeatureFlag.enablePreviewHeaderAndSummary,
  [VitecNextProduct.VALIDATE_AD]: FeatureFlag.enableValidateAd
}

import { defaultSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useAsyncFunction } from '../hooks/useAsyncFunction'
import { isVitecNextProductEnabled } from '../libs/CloudFunctionsApiHandler'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Colors from '../../../../pure/libs/Colors'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import FigmaText from './FigmaText'
import Loader from './Loader'
import { VitecNextOrderItemButton } from './VitecNextOrderItemButton'

export const VitecNextOrderItem = ({
  vitecNextProduct,
  vitecNextOrder
}: {
  vitecNextProduct: VitecNextProduct
  vitecNextOrder: VitecNextOrder
}) => {
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]
  const { textKey, imageKey, descriptionKey, buttons, StatusComponent = () => null } = vitecNextProductInfoItem

  const appContext = useAppState()
  const { state } = appContext
  const vitecNextOrderId = vitecNextOrder._id

  const { data: isProductEnabled, isLoading: isLoadingProduct } = useAsyncFunction(() =>
    isVitecNextProductEnabled({ vitecNextOrderId, vitecNextProduct }, state)
  )

  return (
    <Container fullWidth align="center" fullHeight>
      <Box fullPadding>
        <FigmaImageContainer imageKey={imageKey} />
      </Box>
      <InnerContainer
        key={vitecNextProductInfoItem.slug}
        spacing={defaultSpacing}
        fullPadding
        align="center"
        fullWidth
        fullHeight
        justify="space-between"
      >
        <Box fullWidth align="center">
          <Box fullWidth align="center" fullHeight>
            <Box fullWidth>
              <FigmaText textKey={textKey} />
              <Box top spacing={smallSpacing}>
                <FigmaText textKey={descriptionKey} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box fullWidth top>
          <Box fullWidth>
            <StatusComponent vitecNextOrder={vitecNextOrder} isProductEnabled={isProductEnabled} />
          </Box>
          <Box top pointer alignSelf="flex-start" direction="row" align="center">
            {isLoadingProduct && <Loader />}
            {!isLoadingProduct &&
              buttons.map((button, i) => (
                <VitecNextOrderItemButton
                  key={i}
                  button={button}
                  vitecNOOrder={vitecNextOrder}
                  vitecNextProduct={vitecNextProduct}
                  isProductEnabled={isProductEnabled}
                  index={i}
                />
              ))}
          </Box>
        </Box>
      </InnerContainer>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  box-shadow: ${Shadows.large};
  width: 400px;
  background-color: ${Colors.white};
`

const InnerContainer = styled(Box)`
  background-color: ${Colors.neutral30};
  border-bottom-left-radius: ${BorderRadixes.regular};
  border-bottom-left-radius: ${BorderRadixes.regular};
`

/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-restricted-globals */
import styled from '@emotion/styled'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'
import Colors from '../../../../pure/libs/Colors'
import { withStopProgagation } from '../../../../pure/libs/HtmlHelper'
import RoutePath from '../../../../pure/libs/RoutePath'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { littleSpacing, mediumSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import FigmaText from './FigmaText'
import { MenuItem } from './MenuItem'

type Props = {
  anchorEl?: HTMLElement
  onClose: (e?) => void
}

export default function UserWidgetMenu(props: Props) {
  const { anchorEl } = props
  const { state } = useAppState()
  const navigate = useNavigate()

  const { user } = state

  const onLogout = () => {
    props.onClose()
    navigate(RoutePath.ROOT)
  }

  const onClose = withStopProgagation(props.onClose)

  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItemTitle textKey={TextsDeprecated.usermenuUserNameButtonText} text={user.email?.toString()} />

        <MenuItem onClick={() => navigate(RoutePath.SETTINGS)} textKey={TextsDeprecated.userMenuSettingsButtonText} />

        <Divider />

        <MenuItem
          onClick={() => {
            throw new Error('Not implemented')
          }}
          textKey={TextsDeprecated.userMenuLogoutButtonText}
        />
      </Menu>
    </div>
  )
}

const MenuItemTitle = styled(FigmaText)`
  padding: ${littleSpacing} ${mediumSpacing};
  background-color: ${Colors.primary100};
  display: block;
`

export enum SlackChannels {
  ERRORS = 'errors',
  DEBUG = 'debug',
  OPS_LEOPARD = 'ops-leopard',
  CUSTOMER_SUPPORT = 'customer-support',
  OPS_LEOPARD_QA = 'ops-leopard-qa',
  OPS_USED_SESSIONS = 'ops_used_sessions',
  OPS_SEND_GRID = 'ops_sendgrid',
  OPS_GAZELLE_FASAD = 'ops_gazelle_fasad',
  OPS_LEOPARD_IMAGE_CAPTIONS = 'ops-leopard-image-captions',
  PRODUCT_LONG_SHORT_DESCRIPTION_DEBUG = 'product-long-short-description-debug',
  OPS_EM1_ESTATES_FOR_SALE = 'ops-em1-estates-for-sale'
}

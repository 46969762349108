import { _getDefaultCountryCode, _getDefaultLanguageCode, _getLanguageCode } from './useLanguage.js'
import { Language } from '../../../../pure/types/Antiloop.js'
import { SUPPORTED_COUNTRY_CODES } from '../libs/i18nHelper.js'
import { en, LANGUAGES } from '../../../../pure/libs/Consts.js'
import config from '../Config.js'

export const getLanguageCode = (): Language => {
  if (config.enableNOLanguage) return 'no'
  return _getLanguageCode() || getDefaultLanguageCode()
}

export const useLanguage = getLanguageCode

export function getDefaultLanguageCode(): Language {
  const defaultLanguageCode = _getDefaultLanguageCode()
  if (!defaultLanguageCode) return en
  if (LANGUAGES.includes(defaultLanguageCode)) return defaultLanguageCode
  return en
}

export function getDefaultCountryCode(): string {
  const defaultCountryCode = 'SE'
  const countryCode = _getDefaultCountryCode()

  if (!countryCode) return defaultCountryCode
  if (SUPPORTED_COUNTRY_CODES.includes(countryCode)) return countryCode
  return defaultCountryCode
}

import React from 'react'

import Iframe from '../components/GoogleDocsIframe'
import config from '../Config'
import { Layout } from '../components/Layout'
import MenuVitecNext from '../components/MenuVitecNext'

// test Data: http://localhost:5173/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87
// Prod: https://app.gazelle.work/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87

export const VitecNextFAQPage = () => {
  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Iframe url={config.faqUrl} name="Gazelle Vitec Next FAQ" />
    </Layout>
  )
}

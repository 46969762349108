import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { BUTTON_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import Button from './Button'
import { MenuButtonsDesktop, onClickCreateAd } from './MenuButtonsDesktop'
import { MenuLogo } from './MenuLogo'
import UserWidget from './UserWidget'

export default function MenuLoggedIn() {
  const navigate = useNavigate()
  return (
    <Box fullWidth direction="row" align="center" justify="space-between" backgroundColor="white" left right>
      <Container direction="row" align="center">
        <MenuLogo />
        <Box left spacing={smallSpacing} pointer>
          <Box top spacing={tinySpacing}></Box>
        </Box>
      </Container>
      <Box top spacing={smallSpacing}>
        <MenuButtonsDesktop />
      </Box>
      <Container>
        <Box fullWidth align="flex-end">
          <Box align="flex-end">
            <Box direction="row" align="center">
              <Box right spacing={smallSpacing} pointer onClick={() => onClickCreateAd(navigate)}>
                <Button buttonType="secondary" textKey={Texts.createAd} spacing={smallSpacing} />
              </Box>
              <UserWidget />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const Container = styled(Box)`
  width: ${BUTTON_CONTAINER_WIDTH}px;
`

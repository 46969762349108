import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { VitecNextProductInfoItemLoadingProps } from '../types/VitecNextProductTypes'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus'

export const VitecNextOrderItemStatusLongAndShortDescription = (props: VitecNextProductInfoItemLoadingProps) => {
  const { isProductEnabled } = props
  const { data: vitecNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)

  if (!vitecNextOrder) return null

  return (
    <VitecNextOrderItemStatus
      vitecNextOrder={vitecNextOrder}
      vitecNextProduct={VitecNextProduct.SHORT_LONG_DESCRIPTION}
      isProductEnabled={isProductEnabled}
      enableDoneStatus={false}
    />
  )
}

import styled from '@emotion/styled'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import React from 'react'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Colors from '../../../../pure/libs/Colors'
import { capitalize } from '../../../../pure/libs/Common'
import config from '../Config'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import useAppStateSession from '../hooks/useAppStateSession'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useVitecNextEstateForOrder } from '../hooks/useVitecNextEstateForOrder'
import { HardCodedTexts, Texts } from '../libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { LoadingPage } from './LoadingPage'
import MenuVitecNext from './MenuVitecNext'
import { VitecNextOrderItem } from './VitecNextOrderItem'

// test Data: http://localhost:5173/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87
// Prod: https://app.gazelle.work/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87

export const VitecNextOrder: React.FC<{ vitecNextOrderId: string }> = ({ vitecNextOrderId }) => {
  const { data: vitecNOOrder } = useVitecNOOrder(vitecNextOrderId)
  const { state, setState } = useAppStateSession()
  const { enablePreview } = state
  const enablePreviewDashboard = useFeatureFlag(FeatureFlag.enablePreviewDashboard)

  const { data: estate } = useVitecNextEstateForOrder({ orderId: vitecNextOrderId, useCache: true })
  const address = [estate?.address?.streetAdress, estate?.address?.apartmentNumber, estate?.address?.city]
    .filter((s) => s)
    .map((s) => capitalize(s?.toLocaleLowerCase()))
    .join(', ')

  if (!vitecNOOrder) return <LoadingPage />

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullWidth align="center" spacing={bigSpacing} top>
        <Box>
          <Box visibility={address ? 'visible' : 'hidden'}>
            <FigmaText textKey={HardCodedTexts.orderViewHeader} text={address} />
          </Box>
          <Box>
            <FigmaText textKey={Texts.dashboardFeaturesHeader} />
          </Box>
          {enablePreviewDashboard && (
            <Box fullWidth align="flex-end">
              <Box direction="row" align="center">
                <Box onClick={() => setState({ ...state, enablePreview: !state.enablePreview })} pointer>
                  {enablePreview ? <StyledCheckboxIcon /> : <CheckBoxOutlineBlankIcon color={'white' as any} />}
                </Box>
                <Box left spacing={smallSpacing}>
                  <FigmaText textKey={Texts.dashboardSettingPreviewBeforeSave} />
                </Box>
              </Box>
            </Box>
          )}
          <Box direction="row" fullHeight top>
            {[
              config.enableImageCaptionsProduct ? VitecNextProduct.IMAGE_CAPTIONS : undefined,
              config.enableByggemateProduct ? VitecNextProduct.BYGGEMÅTE : undefined,
              config.enableLongAndShortProduct ? VitecNextProduct.SHORT_LONG_DESCRIPTION : undefined
            ]
              .filter((v) => !!v)
              .map((vitecNextProduct, i) => (
                <Box left={i > 0} key={i}>
                  <VitecNextOrderItem
                    vitecNextOrder={vitecNOOrder}
                    vitecNextProduct={vitecNextProduct as VitecNextProduct}
                  />
                </Box>
              ))}
          </Box>
          <Box direction="row" fullHeight top>
            {[config.enableValidateAdProduct ? VitecNextProduct.VALIDATE_AD : undefined]
              .filter((v) => !!v)
              .map((vitecNextProduct, i) => (
                <Box left={i > 0} key={i}>
                  <VitecNextOrderItem
                    vitecNextOrder={vitecNOOrder}
                    vitecNextProduct={vitecNextProduct as VitecNextProduct}
                  />
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

const StyledCheckboxIcon = styled(CheckBoxIcon)`
  color: ${Colors.white};
`

import styled from '@emotion/styled'
import React from 'react'
import toast from 'react-hot-toast'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Colors from '../../../../pure/libs/Colors'
import { FIVE_SECONDS } from '../../../../pure/libs/Consts'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { fulfillJaguarOrder } from '../libs/CloudFunctionsApiHandler'
import { createLeopardOrder } from '../libs/ProcessHandler'
import { captureException } from '../libs/SentryHelper'
import { Texts } from '../libs/Texts'
import Box from './Box'
import Button from './Button'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { useUploadedFiles } from '../hooks/useUploadedFiles'

export function VitecNextOrderProductButtons(props: {
  vitecNextOrder: VitecNextOrder
  vitecNextProduct: VitecNextProduct
  suffixElement?: React.ReactNode
  fulfillMentSuccessText: string
}) {
  const { vitecNextProduct, suffixElement, fulfillMentSuccessText } = props
  const { data: vitecNextOrder = props.vitecNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]

  const { data: files = [] } = useUploadedFiles(leopardOrder?.requestId)
  const checkedFiles = files.filter((file) => file.metaData?.checked !== false)
  const context = useAppState()
  const { state } = context
  const [isRegenerating, setisRegenerating] = React.useState(false)
  const [isFulfilling, setIsFulfilling] = React.useState(false)
  const isLoading = isFulfilling || isRegenerating

  if (!leopardOrder) return null

  const onClickFulfillLeopardOrder = () =>
    Promise.resolve(setIsFulfilling(true))
      .then(() => fulfillJaguarOrder({ vitecNextOrder, requestId: leopardOrder.requestId }, state))
      .then(() =>
        toast.success(`Success`, {
          icon: '👍',
          duration: FIVE_SECONDS,
          style: {
            backgroundColor: Colors.success60
          }
        })
      )
      .catch(captureException)
      .finally(() => setIsFulfilling(false))

  const onClickCreateLeopardOrder = () =>
    Promise.resolve(setisRegenerating(true))
      .then(() => createLeopardOrder({ vitecNextOrder, vitecNextProduct }, context))
      .then(() => setisRegenerating(false))

  return (
    <Container fullWidth fullPadding>
      <Box fullWidth direction="row" justify="space-between" align="center">
        <Box fullWidth direction="row" align="center">
          <Box>
            {leopardOrder.status !== 'done' && (
              <Button
                buttonType="secondary"
                textKey={Texts.standardCtaUploadToNext}
                spacing={smallSpacing}
                onClick={onClickFulfillLeopardOrder}
                disabled={isRegenerating}
              />
            )}
            {leopardOrder.status === 'done' && (
              <VitecNextOrderItemStatusView
                type="success"
                textKey={Texts.ImageTextsNotificationItCompleted}
                text={fulfillMentSuccessText}
              />
            )}
          </Box>
          <Box left>
            <Button
              buttonType="secondary"
              textKey={Texts.standardCtaRegenerate}
              spacing={smallSpacing}
              onClick={onClickCreateLeopardOrder}
              disabled={isLoading}
            />
          </Box>
        </Box>
        {suffixElement || <Box />}
      </Box>
      {!isLoading && leopardOrder.status === 'failed' && (
        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderItemStatusView type="error" textKey={Texts.standardErrorVitect} />
        </Box>
      )}
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

// @flow
import { createTheme } from '@mui/material'
import Colors from '../../../../pure/libs/Colors'
import { blue } from '@mui/material/colors'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

export const DefaultMaterialTheme = {
  palette: {
    primary: createColor(Colors.primary100),
    primary20: createColor(Colors.primary20),
    primary40: createColor(Colors.primary40),
    secondary: createColor(Colors.secondary100),
    secondary40: createColor(Colors.secondary40),
    secondary60: createColor(Colors.secondary60),
    neutral30: createColor(Colors.neutral30),
    white: createColor(Colors.white),
    blue: createColor('#0052B4')
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#000'
      }
    }
  }
}

const theme = createTheme(DefaultMaterialTheme)

export default theme

import _config, { ConfigType } from '../Config'
import { OrgUserRole } from '../../../../pure/types/types'
import useAppState, { State } from './useAppState'

export default function useLoggedInConfig(): ConfigType {
  const { state } = useAppState()
  const config = _config

  return getLoggedInConfig(config, state)
}

export function getLoggedInConfig(config: ConfigType, state: State): ConfigType {
  return config
}

export function isLimitedUser({ user }: State) {
  if (!user) return true

  return user.role === OrgUserRole.LIMITED
}

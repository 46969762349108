import dayjs from 'dayjs'
import { LeopardOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { JAGUAR_RESPONSE_TIMEOUT_MINUTES } from '../../../../pure/libs/Consts'

// TODO WRITE TEST, should show status component if finishedAt is less than 5 minutes ago
export function isCurrentStatus(o?: LeopardOrder, now = dayjs()) {
  if (!o?.finishedAt) return false
  return dayjs(o?.finishedAt)
    .add(JAGUAR_RESPONSE_TIMEOUT_MINUTES, 'minutes')
    .isAfter(now)
}

export function isProcessingJaguarResponse(jaguarResponseStatus?: LeopardOrder) {
  return ['processing', 'retrying'].includes(jaguarResponseStatus?.status || '')
}

export function isTimedoutJaguarResponse(jaguarResponseStatus?: LeopardOrder) {
  return (
    isProcessingJaguarResponse(jaguarResponseStatus) &&
    dayjs(jaguarResponseStatus?.createdAt)
      .add(JAGUAR_RESPONSE_TIMEOUT_MINUTES, 'minutes')
      .isBefore(dayjs())
  )
}

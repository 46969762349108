import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItemLoadingProps } from '../types/VitecNextProductTypes'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

export const VitecNextOrderItemStatusByggemate = (props: VitecNextProductInfoItemLoadingProps) => {
  const { isProductEnabled } = props
  const { data: vitecNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const requestId = vitecNextOrder?.jaguarResponses?.BYGGEMÅTE?.requestId
  const { data: upload } = useUpload(requestId)

  if (!vitecNextOrder) return null

  if (isProductEnabled?.noOfDocuments === 0)
    return <VitecNextOrderItemStatusView textKey={Texts.ConstructionNotificationCiMissingDocument} type="warning" />

  return (
    <VitecNextOrderItemStatus
      vitecNextOrder={vitecNextOrder}
      vitecNextProduct={VitecNextProduct.BYGGEMÅTE}
      upload={upload}
      isProductEnabled={isProductEnabled}
    />
  )
}

import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Colors from '../../../../pure/libs/Colors'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { onCopyText } from '../libs/onClickCopy'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { ButtonCopy } from './ButtonCopy'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { VitecNextOrderArrowBackIcon } from './Icons'
import { LoadingPage } from './LoadingPage'
import { NoWrapFigmaText } from './StyledComponents'
import { VitecNextOrderProductButtons } from './VitecNextOrderProductButtons'
import { getFigmaText } from '../libs/TextRepository'

export const VitecNextOrderProductContructionInfo = ({ vitecNextOrderId }: VitecNextOrderProductProps) => {
  const navigate = useNavigate()
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.BYGGEMÅTE
  const { data: uploadedFiles = [] } = useUploadedFiles(leopardOrder?.requestId || '')

  const items = uploadedFiles
    .reduce(
      (a, file) =>
        a.concat(
          Object.entries(file.PropertyAdditionalInfo || {}).map(([k, v]) => ({
            header: k,
            description: v as string,
            file
          }))
        ),
      [] as any[]
    )
    .sort((a, b) => a.header.localeCompare(b.header))

  const onClickCopy = () =>
    onCopyText(items.reduce((acc, { header, description }) => `${acc}${header}\n${description}\n\n`, ''))

  if (isLoading) return <LoadingPage />
  if (!vitecNextOrder) return <Box />

  return (
    <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
      <Box>
        <Box direction="row" link onClick={() => navigate(-1)}>
          <Box>
            <VitecNextOrderArrowBackIcon />
          </Box>
          <Box left spacing={smallSpacing}>
            <NoWrapFigmaText textKey={Texts.standardReturnToDashboard} />
          </Box>
        </Box>
      </Box>
      <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
        <Box fullWidth>
          <FigmaText textKey={Texts.ConstructionCiHeaderText} />
        </Box>
        <Box top>
          <FigmaImageContainer imageKey={Images.gazelleInCircle} />
        </Box>
        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderProductButtons
            vitecNextOrder={vitecNextOrder}
            vitecNextProduct={VitecNextProduct.BYGGEMÅTE}
            fulfillMentSuccessText={getFigmaText(Texts.standardNotificationUploadComplete)}
          />
        </Box>
        <Box top spacing={smallSpacing} />
        {leopardOrder?.status === 'processing' && <LoadingPage />}
        {leopardOrder?.status !== 'processing' && (
          <Container fullWidth fullPadding pointer onClick={onClickCopy}>
            <Box direction="row" align="center">
              <FigmaText textKey={Texts.ConstructionCiTextHeader} />
              <Box left>
                <ButtonCopy />
              </Box>
            </Box>
            <Box fullWidth>
              {items?.map(({ header, description }, index) => (
                <Box fullWidth>
                  <Box top spacing={smallSpacing} fullWidth direction="row" align="center">
                    <FigmaTextWithStyle
                      Component={FigmaTextWithLinebreaks as any}
                      separator={<span />}
                      separatorWhenEmptyContent={<br />}
                      type="body1"
                      text={header}
                    />
                  </Box>
                  <Box fullWidth>
                    <Box fullWidth>
                      {!!description && (
                        <FigmaTextWithStyle
                          Component={FigmaTextWithLinebreaks as any}
                          separator={<span />}
                          separatorWhenEmptyContent={<br />}
                          type="body1"
                          text={description}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Container>
        )}
      </Box>
      <Box />
    </Box>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
